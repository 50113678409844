import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, OrbitControls, Sky } from "@react-three/drei";

function Model(props) {
    const { scene } = useGLTF("/Models/Ptr5.glb");
    return <primitive object={scene} {...props} />
}

function App(props) {
    return (
        <Canvas dpr={[1, 2]} shadows camera={{ fov: 45 }} style={{ "position": "inherit" }}>
            <color attach="background" args={["#101010"]} />
            <OrbitControls
                enableDamping
                enablePan
                enableRotate
                enableZoom
            />
            <Stage environment={"sunset"}>
                <Model scale={0.01} />
            </Stage>
            <Sky
                distance={450000}
                sunPosition={[5, 1, 8]}
                inclination={0}
                azimuth={0.25}
                {...props}
            />
            {/* console.log('+++ EXIMPLE  +++') */}
        </Canvas>
    );
}

export default App;
