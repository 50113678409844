import * as React from 'react';
import { Suspense } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PtrGLBModel from './PtrGLBModel';
import CircularProgress from '@mui/joy/CircularProgress';


export default function PtrApp() {

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="false" >
                <Box sx={{
                    bgcolor: '#eeeeee', height: '100vh'
                }}>
                    <Suspense fallback={<Loading />}>
                        <PtrGLBModel />
                    </Suspense>
                </Box>
            </Container>
        </React.Fragment>
    );

    function Loading() {
        return (
            <Box sx={{
                height: '100vh', fontSize: '2rem', fontWeight: '700', position: 'absolute', top: '40%', left: '40%'
            }}>
                <CircularProgress /> ЗАГРУЖАЮ МОДЕЛЬ...
                {/* <CircularProgress size="lg" determinate value={progress} color="primary" /> */}
            </Box>
        );
    }
}
